export const API_ENDPOINTS = {
    LOGIN: 'idp/login',
    REFRESH: 'idp/refresh',
    REGISTER: 'idp/expressregister',
    LOGOUT: 'idp/logout',
    FORGOT_PASSWORD: 'idp/forgotpassword',
    CHANGE_PASSWORD: 'idp/changepassword',
    VERIFY_EMAIL: 'idp/verify',
    RESEND_VERIFY_EMAIL: 'idp/resend',
    FORGOT_PASSWORD_POST: 'idp/confirmforgot',
}