import { AnyAction } from "redux-saga";
import { LOGIN_SUCCESS, LOGOUT, REFRESH_SUCCESS, SET_ACCESS_TOKEN, SET_AUTHENTICATED } from "../../../constants/redux";

interface AuthState {
	isAuthenticated: boolean;
	accessToken: string | null;
	idToken: string | null;
	refreshToken: string | null;
	expiresAt: number | null;
}

const initialState: AuthState = {
	isAuthenticated: false,
	accessToken: null,
	idToken: null,
	refreshToken: null,
	expiresAt: null,
};

const authReducer = (state = initialState, action: AnyAction): AuthState => {
	switch (action.type) {
		case SET_AUTHENTICATED:
			return {
				...state,
				isAuthenticated: action.payload,
			};
		case SET_ACCESS_TOKEN:
			return {
				...state,
				accessToken: action.payload,
			};
		case LOGIN_SUCCESS:
		case REFRESH_SUCCESS:
			return { ...state, isAuthenticated: true, ...action.payload };
		case LOGOUT:
			return initialState;
		default:
			return state;
	}
};

export default authReducer;
