import { Suspense, lazy, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
// import { PrivateRoute, useAuth } from "./auth/useAuth";
import LoadingScreen from "../components/loadingScreen";
import PrivateRoute from "../context/PrivateRoute";
import { connect } from "react-redux";
import { RootState } from "../store/index";

const Dashboard = lazy(() => import("../containers/dashboard"));
const Event = lazy(() => import("../containers/events"));
const Galleries = lazy(() => import("../containers/galleries"));
const Earnings = lazy(() => import("../containers/earnings"));
const Notifications = lazy(() => import("../containers/notifications"));
const Contacts = lazy(() => import("../containers/contacts"));
const Profile = lazy(() => import("../containers/profile"));
const SignUp = lazy(() => import("../components/signUp"));
const Invoice = lazy(() => import("../containers/invoice"));
const SignIn = lazy(() => import("../components/signIn"));

interface Props {
	isAuthenticated: boolean;
}

function AppRoutes({ isAuthenticated }: Props) {
	const navigate = useNavigate();

	useEffect(() => {
		callLogin();
	}, [isAuthenticated]);
	
	const callLogin = () => {
		
		console.log("🚀 ~ callLogin ~ window.location.pathname:", window.location)
		// if (!isAuthenticated) {
		// 	if (window.location.pathname !== "/login")
		// 		return navigate("/login", {
		// 			state: { path: window.location.pathname },
		// 		});
		// }
	};

	return (
		<Suspense fallback={<LoadingScreen />}>
			<Routes>
				<Route path="login" element={<SignIn />} />
				<Route path="signup" element={<SignUp />} />

				<Route
					path=""
					element={
						<PrivateRoute>
							<Dashboard />
						</PrivateRoute>
					}
				/>
				<Route
					path="dashboard"
					element={
						<PrivateRoute>
							<Dashboard />
						</PrivateRoute>
					}
				/>
				<Route
					path="dashboard/events"
					element={
						<PrivateRoute>
							<Event />
						</PrivateRoute>
					}
				/>
				<Route
					path="dashboard/events/event-view"
					element={
						<PrivateRoute>
							<Event />
						</PrivateRoute>
					}
				/>
				<Route
					path="dashboard/contacts"
					element={
						<PrivateRoute>
							<Contacts />
						</PrivateRoute>
					}
				/>
				<Route
					path="dashboard/galleries"
					element={
						<PrivateRoute>
							<Galleries />
						</PrivateRoute>
					}
				/>
				<Route
					path="dashboard/earnings"
					element={
						<PrivateRoute>
							<Earnings />
						</PrivateRoute>
					}
				/>
				<Route
					path="dashboard/notifications"
					element={
						<PrivateRoute>
							<Notifications />
						</PrivateRoute>
					}
				/>
				<Route
					path="dashboard/profile"
					element={
						<PrivateRoute>
							<Profile />
						</PrivateRoute>
					}
				/>
				<Route
					path="dashboard/earnings/invoice"
					element={
						<PrivateRoute>
							<Invoice />
						</PrivateRoute>
					}
				/>
			</Routes>
		</Suspense>
	);
}
const mapStateToProps = (state: RootState) => ({
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, null)(AppRoutes);
