export const saveAuthToStorage = (authData: any) => {
	localStorage.setItem("auth", JSON.stringify(authData));
};

export const loadAuthFromStorage = () => {
	const auth = localStorage.getItem("auth");
	return auth ? JSON.parse(auth) : null;
};

export const clearAuthFromStorage = () => {
	localStorage.removeItem("auth");
};
