import {
	CHANGE_PASSWORD_REQUEST,
	FORGET_PASSWORD_REQUEST,
	LOGIN_SUCCESS,
	LOGOUT,
	REFRESH_SUCCESS,
	SET_ACCESS_TOKEN,
	SET_AUTHENTICATED,
	SIGN_IN,
	SIGNUP_FAILURE,
	SIGNUP_REQUEST,
	SIGNUP_SUCCESS,
} from "../../../constants/redux";

export const signIn = (
	username: string,
	password: string,
	onSuccess: () => void,
	onError: (error: string) => void
) => ({
	type: SIGN_IN,
	payload: { username, password, onSuccess, onError },
});

export const setAuthenticated = (isAuthenticated: boolean) => ({
	type: SET_AUTHENTICATED,
	payload: isAuthenticated,
});

export const setAccessToken = (accessToken: string | null) => ({
	type: SET_ACCESS_TOKEN,
	payload: accessToken,
});

export const loginSuccess = (authData: any) => ({
	type: LOGIN_SUCCESS,
	payload: authData,
});

export const refreshSuccess = (authData: any) => ({
	type: REFRESH_SUCCESS,
	payload: authData,
});

export const logout = () => ({
	type: LOGOUT,
});

export const signupRequest = (
	userData: object,
	onSuccess?: (res: unknown) => void,
	onFailure?: (error: unknown) => void
) => ({
	type: SIGNUP_REQUEST,
	payload: userData,
	onSuccess,
	onFailure,
});

export const signupSuccess = (user: any) => ({
	type: SIGNUP_SUCCESS,
	payload: user,
});

export const signupFailure = (error: string) => ({
	type: SIGNUP_FAILURE,
	payload: error,
});

export const forgetPasswordRequest = (email: string, onSuccess?: () => void, onError?: (error: string) => void) => ({
	type: FORGET_PASSWORD_REQUEST,
	payload: { email },
	onSuccess,
	onError,
});

interface ChangePasswordRequestPayload {
	oldpassword: string;
	newpassword: string;
	onSuccess?: (res: unknown) => void;
	onFailure?: (error: unknown) => void;
}
export const changePasswordRequest = (payload: ChangePasswordRequestPayload) => ({
	type: CHANGE_PASSWORD_REQUEST,
	payload,
});
