import axios, { AxiosInstance } from "axios";
import store from ".";
import { clearAuthFromStorage, saveAuthToStorage as saveAuthDataToStorage  } from "../utils/authStorage";
import { loginSuccess, logout } from "./actions/auth";
import { useNavigate } from "react-router-dom";

const URL = "https://av6ccmjtye.execute-api.us-east-1.amazonaws.com/dev/";

const api: AxiosInstance = axios.create({
	baseURL: URL, // Replace with your API base URL
});


api.interceptors.request.use(
	(config) => {
		const { accessToken: token } = store.getState().auth;

		config.headers["x-api-key"] = `${process.env.REACT_APP_X_API_KEY}`;

		if (config.headers && config.headers.Authorization) {
			let value = config.headers.Authorization;
			config.headers.Authorization = value;
		} else if (token) {
			config.headers.Authorization = `${token}`;
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// Response interceptor to handle 401 errors and refresh the token
api.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const { response } = error;

		// If the error is a 401 (Unauthorized) and refreshToken exists
		if (response?.status === 401 && response?.data?.message === "The incoming token has expired") {
			const { refreshToken } = store.getState().auth;
			console.log("🚀 ~ refreshToken:", refreshToken)

			if (refreshToken) {
				try {
					// Make a request to refresh the token
					const refreshResponse = await axios.post("/idp/refresh", { refreshToken });

					const newAuthData = {
						accessToken: refreshResponse.data.AuthenticationResult.AccessToken,
						idToken: refreshResponse.data.AuthenticationResult.IdToken,
						refreshToken: refreshToken, // Retain the same refresh token
						expiresAt: Date.now() + refreshResponse.data.AuthenticationResult.ExpiresIn * 1000, // Calculate expiration time
					};

					// Save new tokens to localStorage and Redux state
					saveAuthDataToStorage(newAuthData);
					store.dispatch(loginSuccess(newAuthData));

					// Retry the failed request with the new access token
					error.config.headers["Authorization"] = `Bearer ${newAuthData.accessToken}`;
					return axios(error.config);
				} catch (refreshError) {
					console.error("Error refreshing token:", refreshError);
					store.dispatch(logout());
					clearAuthFromStorage();
					return Promise.reject(refreshError);
				}
			}
		}

		return Promise.reject(error);
	}
);

export const commonApiAuthCall = async (
	endpoint: string,
	method: string,
	data?: any,
	queryParams?: {},
	header?: {},
	useFormUrlEncoded?: boolean // New parameter to determine form-urlencoding
) => {
	try {
		const response = await api.request({
			url: queryParams ? buildUrlWithParams(endpoint, queryParams) : endpoint,
			method,
			data: data,
			headers: header,
		});

		return response.data;
	} catch (error) {
		throw error;
	}
};

const buildUrlWithParams = (url: string, params?: Record<string, string>) => {
	if (!params) return url;

	const queryString = Object.keys(params)
		.map((key) => `${key}=${encodeURIComponent(params[key])}`)
		.join("&");

	return `${url}?${queryString}`;
};
