import React from 'react';
import { Navigate, useLocation  } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

const PrivateRoute = ({ children }: any) => {
  const location = useLocation();
  const { accessToken, expiresAt } = useSelector((state: RootState) => state.auth);
  const isAuthenticated = accessToken && expiresAt && Date.now() < expiresAt;

  if (!location?.pathname || !isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Otherwise, render the children components
  return <>{children}</>;
};

export default PrivateRoute;